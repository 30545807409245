import { createRouter, createWebHistory } from 'vue-router'
import fireTraining from './fireTraining' //消防培训
import productMall from './productMall' //产品商城
import fireInfo from './fireInfo' //消防咨询
import fireService from './fireService' //消防服务
import mainPage from './mainPage' //首页
import scientificCenter from './scientificCenter' //科研中心

const web = () => import("@/views/home");

const routes = [
  {
    path: "/",
    redirect: "/web/mainPage",
  },
  {
    path: "/web",
    name: "web",
    component: web,
    children: [
      ...productMall,
      ...fireTraining,
      ...fireInfo,
      ...fireService,
      ...mainPage,
      ...scientificCenter,
      {
          path: "demo",
          name: "demo",
          component: () => import("@/views/demo/demo"),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // base: process.env.NODE_ENV === 'production' ? '/www/' : '/',
  base: process.env.VUE_APP_BASE_ROUTE,
})

router.afterEach((to,from,next) => {
  document.getElementById("main").scrollTop = 0;
  
  });
export default router
